export class RegexConstants {
    public static readonly ShortNameRegex =
        /^[a-zA-Z0-9][a-zA-Z0-9.-]{0,61}[a-zA-Z0-9]*$/;
    public static readonly EmptyStringRegex = /^(?!\s*$).+/;
    public static readonly PasswordRegex =
        /^((?=.*\d)(?=.*[A-Z])(?=.*\W).{8,})$/;
    public static readonly EmailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    /**
     * Regex for finding emails inside a text
     * Reduced expression for complexity, performance and to avoid catastrophic backtracking.
     * We do not allow double quotes in the local part of the address to exclude certain edge cases in cv parsing.
     * Includes a preceeding whitespace for similar reasons.
     */
    public static readonly EmailInTextRegex =
        /\s[A-Z0-9._%+-]{1,64}@[A-Z0-9.-]{1,255}\.[A-Z]{2,63}/gi;
    /**
     * Regex which represents a mime type.
     * I copied the regex from the implementation of https://github.com/react-dropzone/react-dropzone
     */
    public static readonly mimeTypeRegex = /\w+\/[-+.\w]+/g;
    /**
     * Regex which represents a file extension.
     * I copied the regex from the implementation of https://github.com/react-dropzone/react-dropzone
     */
    public static readonly fileExtensionRegex = /^.*\.\w+$/;

    public static readonly IsoDateFormat =
        /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:Z|[+-]([\d|:]*))?$/;

    /**
     *When RichTextEditor has no value, then it returns <html><head><\/head><body><p><br><\/p><\/body><\/html>
     */
    public static readonly EmptyRichTextRegEx =
        /^<html><head><\/head><body><p><br><\/p><\/body><\/html>$/;

    /**
     * Regex to check if a string is a pure number.
     * This is used to check if a string is a valid number for a number input.
     * We need this because parseInt("123abc") returns 123 and we want to avoid this by checking the input before.
     */
    public static readonly PureNumberRegex = /^\d+$/;

    /**
     * Regex to find links in PDF-files that are generated by the CV-Generator to mark a field as a merge-field.
     * Do not copy as the positive lookbehind is not supported in all browsers
     */
    public static readonly FileLinkInPdfRegex = /(?<=file:\/\/\/)([\w~]*)/gi;

    /**
     * Extracts the content in between the anchor (<a>) tag from html string
     */
    public static readonly AnchorTagContentRegex = /<a[^>]*>([^<]+)<\/a>/;

    /**
     * Regular expression to match both quoted and unquoted filenames
     */
    public static readonly FilenameRegex = /filename="?([^"]+)"?/;
}
