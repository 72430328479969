import { createTheme } from '@mui/material/styles';
import { decidaloMaterialDefaultsNewDesign } from '../decidalo-material-defaults-new-design/decidalo-material-defaults-new-design';
import decidaloThemePalette from './decidalo-theme-palette-new-design.module.less';

const decidaloTheme = createTheme(decidaloMaterialDefaultsNewDesign, {
    palette: {
        type: 'light',
        primary: {
            main: decidaloThemePalette.primaryColor,
            light: decidaloThemePalette.basicBackgroundColor,
            dark: decidaloThemePalette.brandGrey,
        },
        secondary: {
            main: decidaloThemePalette.basicBackgroundColor,
        },
        error: {
            main: decidaloThemePalette.error,
        },
    },
});

export { decidaloTheme, decidaloThemePalette };
