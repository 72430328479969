/**
 * Relates to the Decidalo.Common.Constants in the backend and can be used for checking for these error codes in the frontend.
 */
export enum ErrorCodesConstants {
    ErrorNotNull = 'ERR001',
    ErrorLengthCheck = 'ERR002',
    ErrorRegex = 'ERR003',
    ErrorNotInList = 'ERR004',
    ErrorUniqueEntryAlreadyExists = 'ERR005',
    ErrorNotAllowed = 'ERR006',
    ErrorDocumentExtension = 'ERR103',
    ErrorDocumentMimeType = 'ERR104',
    ErrorRecentlyUsedAi = 'ERR201',
}
