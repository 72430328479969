import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/en-gb';
import i18next from 'i18next';
import { Month } from '@decidalo-frontend/models';
import { convertToDayjsLocaleString } from './localization-string-parser';

dayjs.extend(relativeTime);
dayjs.extend(utc);

function getDecimalDelimiter(locale?: string): string {
    if (locale === undefined) locale = getCurrentLocale();

    // use this number to test which character is used as the decimal delimiter
    const testString = (1.9).toLocaleString(locale);

    if (testString.length !== 3) {
        throw new Error(
            'Unexpected length of the string used to extract the delimiter.',
        );
    }
    return testString[1];
}

/**
 * Function used to convert a string into a number. All symbols except for the negative sign "-" and the locale-specific decimal delimiter are ignored.
 * @param number The string that should be parsed.
 * @param locale The localization in which the number has to be read. By default, this is the selected language used for the translation.
 * @returns The parsed number. Returns NaN if parsing is not possible.
 */
export function parseNumber(number: string, locale?: string): number {
    const decimalDelimiter = getDecimalDelimiter(locale);

    // Create an array of the parts of the number before and after the decimal delimiter
    const numberArray = number.split(decimalDelimiter);
    if (numberArray.length > 2) {
        // Unexpected length of the array. This is considered a non-number entry
        return NaN;
    }

    //returns NaN if any unexpected chars occur (allowed chars are digits, " ", ",", "." and "-")
    //this avoids that the user can input a completely wrong format
    for (const element of numberArray) {
        if (!/^(\d|,|\.| |-)*$/g.test(element)) {
            return NaN;
        }
    }

    if (number.length === 0) {
        // If nothing is entered, that is not a number
        return NaN;
    }

    const numbers = numberArray.map((num, index) => {
        // Strip each string of all characters including thousand separator.
        const plainNumeral = num.replace(/\D/g, '');
        if (plainNumeral.length === 0) {
            // It is allowed to enter nothing before the decimal separator as it is customary in the english language to drop leading zeroes, i.e ".2" for 1/5
            if (index === 0 && numberArray.length > 1) return '0';
            return null;
        }
        return plainNumeral;
    });
    // if nothing or random characters are entered after the comma or if there is no decimal part and the entered character are not digits
    if (numbers[1] === null || numbers[0] === null) {
        // then this is not considered to be a number
        return NaN;
    }

    return Number.parseFloat(
        // Test for a negative sign
        (number[0] === '-' ? '-' : '') + numbers.join('.'),
    );
}

/**
 * Extension of the parseNumber function which will return null instead of NaN, if the given string only contains whitespace.
 */
export function parseNumberOrNull(
    number: string,
    locale?: string,
): number | null {
    if (number.trim() === '') {
        return null;
    } else {
        return parseNumber(number, locale);
    }
}

/**
 * Helper function that checks whether string or number is null or undefined
 */
export function isNullOrUndefined(value?: string | number | null) {
    return value === undefined || value === null;
}

/**
 * Rounds a number to a specified number of decimal places.
 *
 * @param num - The number to be rounded.
 * @param decimalPlaces - The number of decimal places to round to.
 * @returns The rounded number.
 *
 * @example
 * ```typescript
 * const result = round(123.4567, 2);
 * console.log(result); // 123.46
 * ```
 */
export function round(num: number, decimalPlaces: number): number {
    const factor = Math.pow(10, decimalPlaces);
    return Math.round(num * factor) / factor;
}

/**
 * Function to parse a date from a given string.
 * @param date String that should be parsed.
 * @param locale The locale in which the entry should be parsed. By default, the selected language for translations is chosen. This will also allow the parsing of written months.
 * @param format The format in which the string is given. If an array is provided, the first matching format will be chosen. By default, there will be some formats provided for English and German.
 * @returns The parsed date.
 */
export function parseDate(
    date: string,
    locale?: string,
    format?: string | string[],
): Date {
    if (locale === undefined) locale = getCurrentLocale();

    if (format === undefined) {
        if (locale.toLowerCase() === 'en-us') {
            format = [
                'MMMM-DD-YYYY',
                'MMMM-D-YYYY',
                'MMM-DD-YYYY',
                'MMM-D-YYYY',
                'MM-DD-YYYY',
                'MM-D-YYYY',
                'M-DD-YYYY',
                'M-D-YYYY',
                'MMMM-DD-YY',
                'MMMM-D-YY',
                'MMM-DD-YY',
                'MMM-D-YY',
                'MM-DD-YY',
                'MM-D-YY',
                'M-DD-YY',
                'M-D-YY',
            ];
        } else {
            format = [
                'DD-MMMM-YYYY',
                'D-MMMM-YYYY',
                'DD-MMM-YYYY',
                'D-MMM-YYYY',
                'DD-MM-YYYY',
                'D-MM-YYYY',
                'DD-M-YYYY',
                'D-M-YYYY',
                'DD-MMMM-YY',
                'D-MMMM-YY',
                'DD-MMM-YY',
                'D-MMM-YY',
                'DD-MM-YY',
                'D-MM-YY',
                'DD-M-YY',
                'D-M-YY',
            ];
        }
    }

    dayjs.extend(customParseFormat);
    return dayjs(date, format, convertToDayjsLocaleString(locale)).toDate();
}

/**
 * Function to convert utc date to local date.
 * @param date Date that should be converted.
 * @returns The local date.
 */
export function convertUtcToLocalDate(date: Date): Date {
    return dayjs.utc(date).local().toDate();
}

/**
 * Function to interpret a Dayjs object as a utc date, ignoring any timezone information.
 */
export function interpretDayJsAsUTCDate(value: Dayjs | null) {
    return value?.isValid() ? value.utc(true).toDate() : null;
}

/**
 * Function to format date to local date string.
 * @param date Date that should be formatted.
 * @returns The local date string.
 */
export function formatToLocalDateString(date: Date): string {
    return date.toLocaleDateString(getCurrentLocale());
}

/**
 * Function to format date to local date and time string.
 * @param date Date that should be formatted.
 * @returns The local date and time string.
 */
export function formatToLocalDateTimeString(date: Date): string {
    return date.toLocaleDateString(getCurrentLocale(), {
        hour: 'numeric',
        minute: 'numeric',
    });
}

/**
 * Shifts a date, so the conversion to UTC (e.g. getUTCDate()) remains the same date and time, but not the same moment.
 * Should be used especially for date-pickers.
 * @param date Date to be converted.
 * @returns Date object where the time is shifted by the amount of the UTC-offset.
 */
export function getAsUtcDate(date: Date): Date {
    return dayjs(date).utc().add(dayjs().utcOffset(), 'm').toDate();
}

function getCurrentLocale() {
    return i18next.language;
}

export function getMonthList(
    format: 'long' | 'short' = 'long',
): { index: Month; name: string }[] {
    const monthList: Month[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    // We changed getMonthName so that month is 1-based everywhere in the application.
    return monthList.map((value) => ({
        index: value,
        name: getMonthName(value, format),
    }));
}

export const getMonthName = (
    monthIndex: Month,
    format: 'long' | 'short' = 'long',
) => {
    const year = new Date().getFullYear();
    const formatter = new Intl.DateTimeFormat(getCurrentLocale(), {
        month: format,
    });

    // For Intl.DateTimeFormat, january is the 0th month, so we need to shift by one to get the correct name for out conventions
    return formatter.format(new Date(year, monthIndex - 1));
};

export const useGetCreatedInfo = (date: Date, creator: string): string => {
    const { t } = useTranslation();

    if (date == null) {
        return '';
    }

    // TODO: DV3-2404 - inline texts in different headers
    if (dayjs(date).isBefore(dayjs().subtract(1, 'day'))) {
        const translationKey = 'common:components.creation.createdDate';
        return t(translationKey, {
            date: formatToLocalDateString(date),
            creator: creator,
            interpolation: { escapeValue: false },
        });
    }

    const translationKey = 'common:components.creation.created';
    return t(translationKey, {
        date: dayjs(date).fromNow(),
        creator: creator,
    });
};

export const useGetLastEditedInfo = (date: Date, editor: string): string => {
    const { t } = useTranslation();

    if (date == null) {
        return '';
    }

    if (dayjs(date).isBefore(dayjs().subtract(1, 'day'))) {
        const translationKey = 'common:components.lastEdit.updatedDate';
        return t(translationKey, {
            date: formatToLocalDateString(date),
            editor: editor,
            interpolation: { escapeValue: false },
        });
    }

    const translationKey = 'common:components.lastEdit.updated';
    return t(translationKey, {
        date: dayjs(date).fromNow(),
        editor: editor,
    });
};
