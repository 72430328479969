/**
 * Defines the levels of feature permissions.
 */
export enum PermissionScope {
    /**
     * No permissions.
     * Might not always be technically possible.
     */
    None = 0,
    /**
     * Only permission on the own data.
     * This also includes data created by the user.
     */
    Own = 1,
    /**
     * Permission for all own data and data that belongs to the team members.
     * Also recursive team members down the hierarchy are included.
     */
    Team = 2,
    /**
     * All entities (resources) are accessible by this scope.
     */
    All = 3,
}
