import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Stack, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TimelineForm from '../timeline-form/timeline-form';
import TimelineScheduler from '../timeline-scheduler/timeline-scheduler';
import styles from './powerpoint-project-plan-container.module.less';

export function PowerpointProjectPlanContainer() {
    return (
        <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'center'}
        >
            <Stack gap={2}>
                <Typography variant="h1" color={'var(--primaryColor)'}>
                    decídalo
                </Typography>
                {/* <DndProvider backend={HTML5Backend}>
                    <TimelineScheduler />
                </DndProvider> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimelineForm />
                </LocalizationProvider>
            </Stack>
        </Stack>
    );
}

export default PowerpointProjectPlanContainer;
