const NUMBER_OF_BYTES_IN_KB = 1024;
const NUMBER_OF_BYTES_IN_MB = Math.pow(1024, 2);
const NUMBER_OF_BYTES_IN_GB = Math.pow(1024, 3);

/**
 * Converts the given file size in KB, MB or GB depending on it's value.
 * Returns localized string with one digit after decimal point and unit.
 * @param fileSize file size in bytes
 * @returns file size as localized string in KB, MB or GB
 */
export const convertFileSize = (fileSize: number, culture: string) => {
    let fileSizeString = '';
    if (fileSize >= NUMBER_OF_BYTES_IN_GB) {
        fileSize = roundToOneDecimalPoint(fileSize / NUMBER_OF_BYTES_IN_GB);
        fileSizeString = fileSize.toLocaleString(culture) + ' GB';
    } else if (fileSize >= NUMBER_OF_BYTES_IN_MB) {
        fileSize = roundToOneDecimalPoint(fileSize / NUMBER_OF_BYTES_IN_MB);
        fileSizeString = fileSize.toLocaleString(culture) + ' MB';
    } else if (fileSize < NUMBER_OF_BYTES_IN_MB) {
        fileSize = roundToOneDecimalPoint(fileSize / NUMBER_OF_BYTES_IN_KB);
        fileSizeString = fileSize.toLocaleString(culture) + ' KB';
    }
    return fileSizeString;
};

/**
 * Rounds passed number to one digit after decimal point
 * @param numberToRound The number to be rounded
 * @returns The rounded number
 */
const roundToOneDecimalPoint = (numberToRound: number) => {
    return Math.round(numberToRound * 10) / 10;
};
