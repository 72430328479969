import React from 'react';
import { createTheme } from '@mui/material/styles';
import decidaloMaterialDefaultColors from '../decidalo-default-theme/decidalo-default-theme-palette.module.less';

const decidaloMaterialDefaults = createTheme({
    typography: {
        fontFamily: [
            '-apple-system',
            ' BlinkMacSystemFont',
            'Segoe UI',
            'Roboto',
            'Oxygen',
            'Ubuntu',
            'Fira Sans',
            'Droid Sans',
            'Helvetica Neue',
            ' sans-serif',
        ].join(','),
        h1: {
            color: decidaloMaterialDefaultColors.blackTextColor,
            fontSize: '1.3rem',
            fontWeight: '500',
        },
        h2: {
            color: decidaloMaterialDefaultColors.blackTextColor,
            fontSize: '1.2rem',
            fontWeight: '500',
        },
        h3: {
            fontSize: '1.1rem',
            fontWeight: '500',
            color: decidaloMaterialDefaultColors.blackTextColor,
        },
        body1: {
            fontSize: 'inherit',
            color: decidaloMaterialDefaultColors.blackTextColor,
        },
        body2: {},
        subtitle1: {
            color: decidaloMaterialDefaultColors.textColor,
            fontSize: '0.8125rem',
            fontWeight: '400',
            lineHeight: '1.125rem',
        },
        infoText: {
            color: decidaloMaterialDefaultColors.grayTextColor,
            fontStyle: 'italic',
            fontSize: '0.875rem',
            fontWeight: '400',
        },
        toolbarItem: {
            fontSize: 'inherit',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            color: decidaloMaterialDefaultColors.primaryColor,
        },
        warning: {
            fontSize: 'inherit',
            color: decidaloMaterialDefaultColors.warningTextColor,
        },
        /*
        https://decidalo.atlassian.net/browse/DV3-507

        Apparently, some MUI components rely on some typographies to be always defined.
        Despite their documentation also claims that typographies should also be disabled by setting properties to undefined.
        (https://mui.com/material-ui/customization/typography/#adding-amp-disabling-variants)

        Commenting out the following lines is a work-around for the fact the MUI components need or may need defined typographies.

        Type definitions further below should still prevent these typographies to be used in custom solutions (type TypographyVariants and following).
        */
        /*
        h4: undefined, // Disabled. If needed, it requries UI board.
        h5: undefined, // Disabled. If needed, it requries UI board.
        h6: undefined, // Disabled. If needed, it requries UI board.
        button: undefined, // Disabled. If needed, it requries UI board.
        caption: undefined, // Disabled. If needed, it requries UI board.
        subtitle2: undefined, // Disabled. If needed, it requries UI board.
        body2: undefined, // Disabled. If needed, it requries UI board.
        overline: undefined, // Disabled. If needed, it requries UI board.
        */
    },
    components: {
        MuiFilledInput: {
            styleOverrides: {
                input: {
                    backgroundColor:
                        decidaloMaterialDefaultColors.inputBackgroundColor,
                    width: '100%',
                },
            },
        },
        MuiSkeleton: {
            defaultProps: {
                variant: 'text',
                sx: { fontSize: '1rem', maxWidth: '100' },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: (ownerState) => ({
                    ...(ownerState.variant === 'filled' && {
                        backgroundColor:
                            decidaloMaterialDefaultColors.inputBackgroundColor,
                    }),
                }),
            },
            defaultProps: {
                variant: 'filled',
                size: 'small',
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
                size: 'small',
                fullWidth: true,
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                },
            },
        },
        MuiLink: {
            defaultProps: {
                underline: 'hover',
                color: decidaloMaterialDefaultColors.linkColor,
            },
        },
        MuiSnackbar: {
            defaultProps: {
                autoHideDuration: 5000,
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginLeft: '14px',
                    marginRight: '14px',
                },
            },
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    fontSize: '2rem',
                },
                text: {
                    fontSize: '0.75rem',
                },
            },
        },
        MuiStepLabel: {
            styleOverrides: {
                label: {
                    fontSize: 'inherit',
                },
            },
        },
        //  MuiStep
        MuiStepConnector: {
            styleOverrides: {
                root: {
                    top: '1rem',
                    left: 'calc(-50% + 24px)',
                    right: 'calc(50% + 24px)',
                },
            },
        },
        MuiIconButton: {
            defaultProps: {
                color: 'primary',
            },
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.color === 'primary' &&
                        !ownerState.disabled && {
                            '& > svg': {
                                color: decidaloMaterialDefaultColors.primaryColor,
                            },
                            '& > span > span >span.MuiTouchRipple-child': {
                                color: decidaloMaterialDefaultColors.toggledHoverBackgroundColor,
                            },
                        }),
                    ...(ownerState.color === 'error' &&
                        !ownerState.disabled && {
                            '& > svg': {
                                color: decidaloMaterialDefaultColors.errorColor,
                            },
                            '& > span > span >span.MuiTouchRipple-child': {
                                color: decidaloMaterialDefaultColors.errorColor,
                            },
                        }),
                    '&:hover': {
                        backgroundColor:
                            decidaloMaterialDefaultColors.hoverBackgroundColor,
                    },
                    '&:not(:disabled) svg': {
                        color: decidaloMaterialDefaultColors.primaryColor,
                    },
                    textTransform: 'none',
                    width: 'fit-content',
                    color: 'inherit',
                }),
            },
        },
        MuiToggleButton: {
            defaultProps: {
                color: 'primary',
            },
            styleOverrides: {
                root: ({ ownerState }) => ({
                    '& > svg': {
                        height: '1rem',
                    },
                    borderLeft: '1px solid red',
                    borderRadius: '4px',
                    gap: '6px',
                    textTransform: 'none',
                    ...(ownerState.color === 'primary' && {
                        color: decidaloMaterialDefaultColors.primaryColor,
                        borderColor: decidaloMaterialDefaultColors.primaryColor,
                        '&.Mui-selected': {
                            backgroundColor:
                                decidaloMaterialDefaultColors.toggleBackgroundColor,
                        },
                        '&.Mui-selected:hover': {
                            backgroundColor:
                                decidaloMaterialDefaultColors.toggleHoverBackgroundColor,
                        },
                        '&:hover': {
                            backgroundColor:
                                decidaloMaterialDefaultColors.hoverBackgroundColor,
                        },
                    }),
                }),
            },
        },
        MuiToggleButtonGroup: {
            defaultProps: {
                color: 'primary',
            },
            styleOverrides: {
                root: {
                    '& .MuiToggleButtonGroup-grouped:not(:first-of-type)': {
                        border: '1px solid',
                        borderRadius: '4px',
                    },
                    '& .MuiToggleButtonGroup-grouped:not(:last-of-type)': {
                        border: '1px solid',
                        borderRadius: '4px',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: 'inherit',
                    textTransform: 'uppercase',
                    //this sets the height of the icon if its used
                    '& > span > svg': {
                        height: '1rem',
                    },
                },
            },
            defaultProps: {
                size: 'small',
                variant: 'primaryAction',
            },
            variants: [
                {
                    props: { variant: 'primaryAction' },
                    style: ({ theme }) => ({
                        boxShadow: theme.shadows[2], //'0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
                        backgroundColor:
                            decidaloMaterialDefaultColors.primaryColor,
                        color: decidaloMaterialDefaultColors.whiteColor,
                        '&:hover': {
                            backgroundColor:
                                decidaloMaterialDefaultColors.guideToolbarColor,
                        },
                        '&:disabled': {
                            backgroundColor:
                                decidaloMaterialDefaultColors.disabledBackgroundColor,
                            boxShadow: 'none',
                            color: decidaloMaterialDefaultColors.grayTextColor,
                        },
                    }),
                },
                {
                    props: { variant: 'secondaryAction' },
                    style: {
                        boxShadow: 'none',
                        backgroundColor:
                            decidaloMaterialDefaultColors.backgroundColor,
                        border:
                            '1px solid ' +
                            decidaloMaterialDefaultColors.primaryColor,
                        color: decidaloMaterialDefaultColors.primaryColor,
                        '& > span > svg': {
                            color: decidaloMaterialDefaultColors.primaryColor,
                        },
                        '&:hover': {
                            backgroundColor:
                                decidaloMaterialDefaultColors.hoverBackgroundColor,
                        },
                        '&:disabled': {
                            color: decidaloMaterialDefaultColors.grayTextColor,
                            border:
                                '1px solid ' +
                                decidaloMaterialDefaultColors.borderColor,
                            '& > span > svg': {
                                color: decidaloMaterialDefaultColors.grayTextColor,
                            },
                        },
                    },
                },
                {
                    props: { variant: 'errorAction' },
                    style: ({ theme }) => ({
                        boxShadow: theme.shadows[2], //'0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
                        backgroundColor:
                            decidaloMaterialDefaultColors.errorColor,
                        color: decidaloMaterialDefaultColors.whiteColor,
                        '&:hover': {
                            backgroundColor:
                                decidaloMaterialDefaultColors.errorColorHover,
                        },
                    }),
                },
            ],
        },
        MuiChip: {
            styleOverrides: {
                label: {
                    fontSize: '13px',
                    fontWeight: 400,
                    lineHeight: '16px',
                    padding: '0px',
                    alignSelf: 'center',
                },
                root: {
                    borderRadius: '32px',
                    padding: '4px 10px',
                    display: 'flex-start',
                    alignItems: 'flex-start',
                    minWidth: '54px',
                    minHeight: '21px',
                    width: 'fit-content',
                    height: 'fit-content',
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        backgroundColor:
                            decidaloMaterialDefaultColors.hoverBackgroundColor,
                    },
                    '&:disabled': {
                        color: decidaloMaterialDefaultColors.disabledTextColor,
                        opacity: '0.56',
                    },
                    '&:not(.Mui-disabled) svg': {
                        color: decidaloMaterialDefaultColors.primaryColor,
                    },
                    color: decidaloMaterialDefaultColors.blackTextColor,
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        backgroundColor:
                            decidaloMaterialDefaultColors.hoverBackgroundColor,
                    },
                    '&:disabled': {
                        color: decidaloMaterialDefaultColors.disabledTextColor,
                        opacity: '1',
                    },
                    '&:not(.Mui-disabled) svg': {
                        color: decidaloMaterialDefaultColors.primaryColor,
                    },

                    padding: '8px',
                    fontSize: 'inherit',
                    color: decidaloMaterialDefaultColors.blackTextColor,
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    padding: '4px',
                },
            },
        },
        MuiDialog: {
            defaultProps: {
                fullWidth: true,
                maxWidth: 'sm',
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    textAlign: 'start',
                },
            },
        },
        MuiPaper: {
            variants: [
                {
                    props: { variant: 'tile' },
                    style: ({ theme }) => ({
                        borderRadius: '4px',
                        // Generated box shadow style from: Mui Paper variant='elevation' elevation={1}
                        boxShadow: theme.shadows[1], // '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                    }),
                },
                {
                    //TODO: Review variant in DV3-488
                    props: { variant: 'container' },
                    style: ({ theme }) => ({
                        borderRadius: '4px',
                        boxShadow: theme.shadows[1], // '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                        padding: '20px',
                    }),
                },
            ],
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    height: '1rem',
                    borderRadius: '5px',
                },
                bar: {
                    borderRadius: '5px',
                    backgroundColor: decidaloMaterialDefaultColors.primaryColor,
                },
                colorPrimary: {
                    backgroundColor:
                        decidaloMaterialDefaultColors.progressBarBackgroundColor,
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: ({ theme, ownerState }) => ({
                    marginTop: ownerState.disableGutters ? '0px' : '15px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    borderRadius: ownerState.square ? '0px' : '4px',
                    boxShadow: theme.shadows[1],
                    border:
                        '1px solid ' +
                        decidaloMaterialDefaultColors.sectionBorderColor,
                }),
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    flexDirection: 'row-reverse',
                    minHeight: '65px',
                },

                content: {
                    display: 'block',
                },

                expandIconWrapper: {
                    marginRight: '10px',

                    '&.Mui-expanded': {
                        transform: 'rotate(90deg)',
                    },
                },
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    '> *': {
                        marginBottom: '2px',
                    },
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    ...(ownerState.severity === 'error' && {
                        outline: '3px solid',
                        outlineColor: theme.palette.error.light,
                        borderRadius: '5px',
                    }),
                }),
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    boxShadow: '5px 5px 10px 2px rgb(0 0 0 / 25%)',
                },
            },
        },
        MuiImageListItemBar: {
            styleOverrides: {
                root: {
                    background: decidaloMaterialDefaultColors.overlayColor,
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                /*
                The following is a work-around for the MUI Autocomplete component overriding paddings of the outlined input component
                which breaks the layout in some cases where we render custom input components with EndAdornments.
                The following two lines restore the default paddings defined the for MuiOutlinedInput.
                Until we find a case where this breaks layout this should be a sufficient work-around.
                 */
                inputRoot: {
                    '&[class*="MuiOutlinedInput-root"]': {
                        padding: 0,
                        paddingRight: '14px',
                    },
                    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
                        { padding: '8.5px 14px', paddingRight: '0' },
                },
            },
        },

        MuiDrawer: {
            styleOverrides: {
                paper: {
                    boxShadow: '-2px 0px 10px 0px rgba(0, 0, 0, 0.2)',
                    minWidth: '45vw',
                    maxWidth: '45vw',
                    padding: '8px 20px',
                    boxSizing: 'content-box',
                },
            },
        },
    },

    /**
     *These all are material ui default values, we just set theme here explicitly in order to be aware of them
     *and not to change them. Before you think you need to change any of these values, think if you are adjusting everything to conform
     *one special use case and if you can somehow avoid it or do it in another way.
     *Example of adjusting may be found in @see {DecidaloEntitySearchDialog}, where we open drawer from dialog (which is not basic
     *and expected ui flow). Because of that we have added a prop(hasDrawer) if dialog has drawer inside it(or more precisely drawer may be opened
     * inside it). In this case we adjust zIndex inside @see {DecidaloDialog} component to be value of drawer component - 1.
     */

    zIndex: {
        mobileStepper: 1000,
        fab: 1050,
        speedDial: 1050,
        appBar: 1100,
        drawer: 1200,
        modal: 1300,
        snackbar: 1400,
        tooltip: 1500,
    },
});

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        primaryAction: true;
        secondaryAction: true;
        errorAction: true;
        action: true;
        outlined: false;
        contained: false;
    }
}

declare module '@mui/material/ToggleButton' {
    interface ToggleButtonPropsColorOverrides {
        secondary: false;
        error: false;
        info: false;
        success: false;
        warning: false;
        standard: false;
    }
}

declare module '@mui/material/ToggleButtonGroup' {
    interface ToggleButtonGroupPropsColorOverrides {
        secondary: false;
        error: false;
        info: false;
        success: false;
        warning: false;
        standard: false;
    }
}

/*
 * For adjusting typography types, a few redundant type redefinitions are necessary.
 * https://mui.com/material-ui/customization/typography/#adding-amp-disabling-variants
 */
declare module '@mui/material/styles' {
    interface TypographyVariants {
        toolbarItem: React.CSSProperties;
        h1: React.CSSProperties;
        h2: React.CSSProperties;
        h3: React.CSSProperties;
        body1: React.CSSProperties;
        body2: React.CSSProperties;
        subtitle1: React.CSSProperties;
        infoText: React.CSSProperties;
        warning: React.CSSProperties;
    }

    interface TypographyVariantsOptions {
        toolbarItem?: React.CSSProperties;
        h1?: React.CSSProperties;
        h2?: React.CSSProperties;
        h3?: React.CSSProperties;
        body1?: React.CSSProperties;
        body2?: React.CSSProperties;
        subtitle1: React.CSSProperties;
        infoText?: React.CSSProperties;
        warning?: React.CSSProperties;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        toolbarItem: true;
        h1: true;
        h2: true;
        h3: true;
        body1: true;
        warning: true;

        subtitle1: true;
        infoText: true;

        h4: false; // Disabled. If needed, it requries UI board.
        h5: false; // Disabled. If needed, it requries UI board.
        h6: false; // Disabled. If needed, it requries UI board.
        button: false; // Disabled. If needed, it requries UI board.
        caption: false; // Disabled. If needed, it requries UI board.
        inherit: false; // Disabled. If needed, it requries UI board.
        body2: true;
        overline: false; // Disabled. If needed, it requries UI board.
    }
}

declare module '@mui/material/Paper' {
    interface PaperPropsVariantOverrides {
        tile: true;
        container: true;
        elevation: false; // Disabled. If needed, it requries UI board.
    }
}

declare module '@mui/material/IconButton' {
    interface IconButtonPropsColorOverrides {
        default: false;
        secondary: false;
        info: false;
        success: false;
        warning: false;
    }
}

export { decidaloMaterialDefaults };
