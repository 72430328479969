import { createTheme } from '@mui/material/styles';
import { decidaloMaterialDefaults } from '../decidalo-material-defaults/decidalo-material-defaults';
import decidaloDefaultThemePalette from './decidalo-default-theme-palette.module.less';

const decidaloDefaultTheme = createTheme(decidaloMaterialDefaults, {
    palette: {
        type: 'light',
        primary: {
            main: decidaloDefaultThemePalette.primaryColor,
            light: decidaloDefaultThemePalette.primaryLightColor,
            dark: decidaloDefaultThemePalette.primaryDarkColor,
        },
        secondary: {
            main: decidaloDefaultThemePalette.secondaryColor,
        },
        error: {
            main: decidaloDefaultThemePalette.errorColor,
        },
    },
});

export { decidaloDefaultTheme, decidaloDefaultThemePalette };
