/**
 * This class defines constants which can be useful when programming
 */
export class UtilityConstants {
    static emptyString = '';

    static newLine = '\n';

    /**
     * There are places where the data model expects something to have an id, but we don't have one yet.
     * E.g. when creating something and displaying it in the frontend without waiting for the backend response.
     * In these cases we usually use -1 as a placeholder for the id.
     * The idea of this constant is to improve the readability in these cases.
     * But be careful, it is not always a good idea to just pass -1 as an id, when the data model expects it.
     * Ask yourself first, if maybe you need to adjust the data model, to support your use case.
     */
    static emptyIdPlaceholder = -1;
}
