/**
 * This class defines constants which are used in the ui, and aren't determined by the theme.
 * E.g. transition times, debounce times
 */
export class UiConstants {
    /**
     * The duration of the slide transition when entering from outside the screen in ms
     * E.g. Fullscreen dialog enters the screen
     */
    static readonly enterSlideTransitionDuration = 500;
    /**
     * Defines the timeout used in debounced functions,
     * usually used in autocomplete or search functions to prevent multiple backend calls
     */
    static readonly debounceTimeout = 300;
    /**
     * Defines the pen size of some edit components e.g. inline-edit and object-edit.
     * Changes to this constant should also be applied on @pen-icon-size in less files.
     */
    static readonly editPenSize = 16;
    /**
     * Defines the spinner size of edit components e.g. inline-edit.
     */
    static readonly editFieldSpinnerSize = 18;

    /**
     * Defines maximal number of elements that should be shown in autocomplete component
     */
    static readonly maxOptionsCount = 100;

    /**
     * Defines number of milliseconds after which snackbar should be automatically hidden
     */
    static readonly snackbarAutoHideDuration = 4000;

    /**
     * Used to define the width of dropdowns or similar on the account tab of the profile.
     * Not sure if it actually makes sense to have a constant like that, but it is better, than copying
     * the value all over the place.
     */
    static readonly accountTabDropdownWidth = 624;
}
