import React from 'react';

/**
 * Wrapper function for React.memo. It makes the result type-safe which is sometimes needed for generic types.
 * See https://stackoverflow.com/a/70890101 for more details.
 * This function was added for a special case where the compiler couldn't find the correct type for a memoized component that used an intersection type as props.
 */
export const typeSafeMemo: <ComponentType>(
    component: ComponentType,
) => ComponentType = React.memo;
