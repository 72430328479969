import React, { useCallback, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Stack, Grid2 as Grid, Typography, Button, Box } from '@mui/material';
import clsx from 'clsx';
import { useImmer } from 'use-immer';
import styles from './timeline-scheduler.module.less';

const days = Array.from({ length: 90 }, (_, i) => i + 1).map((i) => {
    const date = new Date(Date.now() + (i - 1) * 24 * 60 * 60 * 1000);

    date.setHours(0, 0, 0, 0);
    return date;
});

const daysGroupedByMonth = days.reduce<Record<string, Date[]>>((acc, day) => {
    const monthYear = `${day.getMonth() + 1}-${day.getFullYear()}`;
    if (!acc[monthYear]) {
        acc[monthYear] = [];
    }
    acc[monthYear].push(day);
    return acc;
}, {});

interface Row {
    name: string;
    startDate: Date;
    endDate: Date;
    dragState?: {
        startDate: Date;
        endDate: Date;
    };
}

export function TimelineScheduler() {
    const [rows, setRows] = useImmer<Row[]>([]);

    const addNewRow = useCallback(() => {
        const newDate = new Date(Date.now());
        newDate.setHours(0, 0, 0, 0);
        setRows((prevRows) => [
            ...prevRows,
            {
                name: `Package ${prevRows.length + 1}`,
                startDate:
                    prevRows.length > 0
                        ? prevRows[prevRows.length - 1].startDate
                        : newDate,
                endDate:
                    prevRows.length > 0
                        ? prevRows[prevRows.length - 1].endDate
                        : new Date(newDate.getTime() + 6 * 24 * 60 * 60 * 1000),
            },
        ]);
    }, [setRows]);

    const handleDrop = useCallback(
        (dropDate: Date, row: Row) => (dragStart: Date) => {
            setRows((prevRows) =>
                prevRows.map((r) =>
                    r.name === row.name
                        ? {
                              ...r,
                              startDate: new Date(
                                  r.startDate.getTime() +
                                      (dropDate.getTime() -
                                          dragStart.getTime()),
                              ),
                              endDate: new Date(
                                  r.endDate.getTime() +
                                      (dropDate.getTime() -
                                          dragStart.getTime()),
                              ),
                              dragState: undefined,
                          }
                        : r,
                ),
            );
        },
        [setRows],
    );

    const handleHover = useCallback(
        (dropDate: Date, row: Row) => (dragStart: Date) => {
            setRows((prevRows) => {
                const editedRow = prevRows.find((r) => r.name === row.name);
                if (editedRow) {
                    editedRow.dragState = {
                        startDate: new Date(
                            editedRow.startDate.getTime() +
                                (dropDate.getTime() - dragStart.getTime()),
                        ),
                        endDate: new Date(
                            editedRow.endDate.getTime() +
                                (dropDate.getTime() - dragStart.getTime()),
                        ),
                    };
                }
                // prevRows.map((r) =>
                //     r.name === row.name
                //         ? {
                //               ...r,
                //               dragState: {
                //                   startDate: new Date(
                //                       r.startDate.getTime() +
                //                           (dropDate.getTime() -
                //                               dragStart.getTime()),
                //                   ),
                //                   endDate: new Date(
                //                       r.endDate.getTime() +
                //                           (dropDate.getTime() -
                //                               dragStart.getTime()),
                //                   ),
                //               },
                //           }
                //         : r,
                // )
            });
        },
        [setRows],
    );

    return (
        <Grid
            container
            spacing={0}
            className={styles['grid-content-container']}
        >
            <Grid size={12} className={styles['grid-content-tools']}>
                <Stack direction={'row'} gap={2} paddingBottom={2}>
                    <Button variant="secondaryAction" onClick={addNewRow}>
                        Add row
                    </Button>
                </Stack>
            </Grid>
            <Grid
                size={12}
                className={styles['grid-content-header-days-container']}
            >
                <Stack
                    direction={'row'}
                    gap={0}
                    className={styles['grid-content-header']}
                >
                    {Object.entries(daysGroupedByMonth).map(([month, days]) => (
                        <Stack
                            key={month}
                            alignItems={'center'}
                            className={
                                styles['grid-content-header-month-container']
                            }
                        >
                            <Stack
                                className={styles['grid-content-header-month']}
                            >
                                <Typography variant="body2">{month}</Typography>
                            </Stack>
                            <Stack
                                direction={'row'}
                                className={styles['grid-content-header-days']}
                            >
                                {days.map((day) => (
                                    <Stack
                                        alignItems={'center'}
                                        justifyContent={'center'}
                                        key={day.toISOString()}
                                        className={
                                            styles['grid-content-header-day']
                                        }
                                    >
                                        <Typography variant="body1">
                                            {day.getDate()}
                                        </Typography>
                                    </Stack>
                                ))}
                            </Stack>
                        </Stack>
                    ))}
                </Stack>
                <Stack className={styles['grid-content']}>
                    {rows.map((row) => (
                        <Stack
                            direction={'row'}
                            gap={0}
                            className={styles['grid-content-row']}
                            key={row.name}
                        >
                            {Object.entries(daysGroupedByMonth).map(
                                ([month, days]) => (
                                    <Stack
                                        key={month}
                                        direction={'row'}
                                        className={
                                            styles['grid-content-month-cells']
                                        }
                                    >
                                        {days.map((day) => (
                                            <MemoizedTimelineSchedulerCell
                                                day={day}
                                                row={row}
                                                handleDrop={handleDrop(
                                                    day,
                                                    row,
                                                )}
                                                handleHover={handleHover(
                                                    day,
                                                    row,
                                                )}
                                                key={day.toISOString()}
                                            />
                                        ))}
                                    </Stack>
                                ),
                            )}
                        </Stack>
                    ))}
                </Stack>
            </Grid>
        </Grid>
    );
}

interface ScheduleEntryProps {
    row: Row;
    day: Date;
}

const ScheduleEntry = ({ row, day }: ScheduleEntryProps) => {
    const [{ isDragging }, drag, preview] = useDrag({
        type: 'scheduleEntry',
        item: () => {
            return {
                id: row.name,
                dragStart: day,
            };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    preview(null);

    const startDate = row.dragState?.startDate || row.startDate;
    const endDate = row.dragState?.endDate || row.endDate;

    if (day >= startDate && day <= endDate) {
        return (
            <Box
                className={clsx({
                    [styles['dragging']]: !!row.dragState,
                    [styles['grid-content-cell-start']]:
                        day.getTime() === startDate.getTime(),
                    [styles['grid-content-cell-end']]:
                        day.getTime() === endDate.getTime(),
                    [styles['grid-content-cell-ongoing']]:
                        day.getTime() > startDate.getTime() &&
                        day.getTime() < endDate.getTime(),
                    [styles['grid-content-cell-start-end']]:
                        day.getTime() === startDate.getTime() &&
                        day.getTime() === endDate.getTime(),
                })}
                ref={drag}
            />
        );
    }
    return null;
};

interface TimelineSchedulerCellProps {
    day: Date;
    row: Row;
    handleDrop: (dragStart: Date) => void;
    handleHover: (dragStart: Date) => void;
}

const TimelineSchedulerCell = ({
    day,
    row,
    handleDrop,
    handleHover,
}: TimelineSchedulerCellProps) => {
    const [, drop] = useDrop<
        {
            id: string;
            dragStart: Date;
        },
        void
    >({
        accept: 'scheduleEntry',
        canDrop: (item) => {
            return item.id === row.name;
        },
        drop(item, monitor) {
            if (monitor.canDrop()) {
                handleDrop(item.dragStart);
            }
        },
        hover(item, monitor) {
            if (monitor.canDrop()) {
                handleHover(item.dragStart);
            }
        },
    });

    return (
        <Stack
            key={day.toISOString()}
            className={styles['grid-content-cell']}
            alignItems={'center'}
            justifyContent={'center'}
            ref={drop}
        >
            <ScheduleEntry day={day} row={row} />
        </Stack>
    );
};

const MemoizedTimelineSchedulerCell = React.memo(TimelineSchedulerCell);

export default TimelineScheduler;
