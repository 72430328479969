import { useCallback } from 'react';
import {
    InvalidateQueryFilters,
    QueryKey,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query';

type CustomCommandApiCallbacks<TData = unknown, TVariables = unknown> = {
    /**
     * Callback triggered on successful completion of the method.
     * @param data - The resulting data from the method.
     * @param variables - The variables used in the method.
     * @param invalidateQuery - Query keys or filter function for query keys that should be invalidated.
     * @returns Optionally, a promise or void.
     */
    onSuccess?: (
        data: TData | undefined,
        variables: TVariables,
        invalidateQuery: (
            filters?: InvalidateQueryFilters | undefined,
        ) => Promise<void>,
    ) => void | Promise<unknown>;

    /**
     * Callback triggered on when the method encounters an error.
     * @param error - The error that occurred.
     * @param variables - The variables used in the method.
     * @returns Optionally, a promise or void.
     */
    onError?: (
        error: unknown,
        variables: TVariables,
    ) => void | Promise<unknown>;

    /**
     * Callback triggered regardless of the method success or failure (similar to finally block).
     * @param data - The resulting data from the method (if any).
     * @param error - The error that occurred (if any).
     * @param variables - The variables used in the method.
     * @param invalidateQuery  - Query keys or filter function for query keys that should be invalidated.
     * @returns Optionally, a promise or void.
     */
    onSettled?: (
        data: TData | undefined,
        error: unknown,
        variables: TVariables,
        invalidateQuery: (
            filters?: InvalidateQueryFilters | undefined,
        ) => Promise<void>,
    ) => void | Promise<unknown>;
};

/**
 * Represents the result object returned by the useCommandApi hook.
 * @template TData - The type of data returned from the API call.
 * @template TVariables - The type of variables used in the operation.
 */
export type CommandApiResult<
    TData = unknown,
    TVariables extends unknown[] = unknown[],
> = {
    /**
     * Executes provided service method with the provided variables.
     * @param ...variables - The variables used in the service method.
     * @returns A promise resolving to the resulting data from the method.
     */
    execute: (...variables: TVariables) => Promise<TData>;
    /**
     * The variables used in the service method.
     */
    variables?: TVariables;
    /**
     * The current status of the service mutation.
     * Possible values: 'error', 'idle', 'pending', 'success'.
     */
    status: 'error' | 'idle' | 'pending' | 'success';
    /**
     * Indicates whether the service method is in a pending state.
     */
    isPending: boolean;
    /**
     * Indicates whether the service method was successful.
     */
    isSuccess: boolean;
    /**
     * Indicates whether the service method encountered an error.
     */
    isError: boolean;
    /**
     * Indicates whether the service method is in an idle state.
     */
    isIdle: boolean;
};

/**
 * A custom wrapper for React Query useMutation hook for handling command api calls via service methods.
 * @param serviceMethod A function that performs an asynchronous operation.
 * @param keysToInvalidate An array of query keys to invalidate after the service method is completed.
 * @returns An object with the service method state and methods to interact with it.
 */
export function useCommandApi<
    TData = unknown,
    TVariables extends unknown[] = unknown[],
>(
    serviceMethod: (...variables: TVariables) => Promise<TData>,
    keysToInvalidate?: QueryKey[],
    callbacks?: CustomCommandApiCallbacks<TData, TVariables>,
): CommandApiResult<TData, TVariables> {
    const queryClient = useQueryClient();

    const { mutateAsync, ...mutation } = useMutation({
        mutationFn: (variables: TVariables) => serviceMethod(...variables),
        onSuccess: async (data, variables) => {
            if (keysToInvalidate) {
                await Promise.all(
                    keysToInvalidate.map((key) => {
                        return queryClient.invalidateQueries({ queryKey: key });
                    }),
                );
            }
            return callbacks?.onSuccess?.(
                data,
                variables,

                //This is needed as inside invalidateQuery fn
                //this is being used, which loses context in custom hooks
                //so we need ensure that our hooks still use context
                //of this query client
                queryClient.invalidateQueries.bind(queryClient),
            );
        },
        onError: callbacks?.onError,
        onSettled: (data, error, variables) =>
            callbacks?.onSettled?.(
                data,
                error,
                variables,
                queryClient.invalidateQueries.bind(queryClient),
            ),
    });

    const execute = useCallback(
        (...variables: TVariables) => mutateAsync(variables),
        [mutateAsync],
    );

    return {
        execute: execute,
        variables: mutation.variables,
        status: mutation.status,
        isPending: mutation.isPending,
        isSuccess: mutation.isSuccess,
        isError: mutation.isError,
        isIdle: mutation.isIdle,
    };
}
