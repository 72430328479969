import {
    JSXElementConstructor,
    ReactElement,
    useEffect,
    useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
    Alert,
    Button,
    Snackbar,
    Stack,
    TextField,
    TextFieldProps,
    Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import {
    Milestone,
    WorkPackageBase,
} from '@decidalo-frontend/features/powerpoint-project-plan/data-access/models';
import {
    DeleteProjectPlan,
    GetProjectPlan,
    UpdateProjectPlan,
    useCreateProjectPlanLink,
    useGeneratePowerpointPlan,
} from '@decidalo-frontend/features/powerpoint-project-plan/data-access/service';
import styles from './timeline-form.module.less';

const packageNames = [
    'Workshop',
    'Implementation',
    'Testing',
    'Rollout',
    'Hypercare',
    'Evaluation',
];

const milestoneNames = ['Kickoff', 'Specification', 'Handover'];

const oneWeekInMilliseconds = 7 * 60 * 60 * 24 * 1000;

const initialProjectName = "Startup '25";
const initialWorkPackage = {
    id: 1,
    isPackageGroup: false,
    name: packageNames[0],
    resources: '',
    startDate: new Date(),
    endDate: new Date(Date.now() + oneWeekInMilliseconds),
};

const initialMileStone = {
    id: 1,
    name: milestoneNames[0],
    date: new Date(Date.now() + 2 * 60 * 60 * 24 * 1000),
};

export function TimelineForm() {
    const [projectName, setProjectName] = useState<string>(initialProjectName);

    const [guid, setGuid] = useState<string | null>(null);

    const [showCopyLinkButton, setShowCopyLinkButton] = useState(false);

    useEffect(() => {
        const location = window.location;
        const splitPath = location.pathname.split('/');
        setGuid(splitPath.length > 1 ? splitPath[splitPath.length - 1] : null);
    }, []);

    const [toasterMessage, setToasterMessage] = useState<string | null>(null);

    const [toasterSeverity, setToasterSeverity] = useState<'success' | 'error'>(
        'success',
    );

    const [workPackages, setWorkPackages] = useState<WorkPackageBase[]>([
        initialWorkPackage,
    ]);

    useEffect(() => {
        if (guid) {
            GetProjectPlan(guid)
                .then((projectPlan) => {
                    setWorkPackages(projectPlan.workPackages);
                    setProjectName(projectPlan.name);
                    setMilestones(projectPlan.milestones);
                })
                .catch((e) => {
                    setToasterMessage(
                        'There is no project plan under this URL, or it has been deleted.',
                    );
                    setToasterSeverity('error');
                    setGuid(null);
                    window.history.pushState(
                        {},
                        '',
                        `${window.location.origin}`,
                    );
                });
        }
    }, [guid]);

    const [milestones, setMilestones] = useState<Milestone[]>([
        initialMileStone,
    ]);

    const powerpointPlanGenerator = useGeneratePowerpointPlan();

    const addNewWorkpackage = () => {
        setWorkPackages((workPackages) => {
            const prevWorkPackage =
                workPackages.length > 0
                    ? workPackages[workPackages.length - 1]
                    : null;
            const workshopId = prevWorkPackage?.id ? prevWorkPackage.id + 1 : 1;
            const newWorkPackage: WorkPackageBase = {
                id: workshopId,
                name: packageNames[(workshopId - 1) % packageNames.length],
                isPackageGroup: false,
                resources: '',
                startDate: prevWorkPackage?.startDate ?? new Date(),
                endDate:
                    prevWorkPackage?.endDate ??
                    new Date(Date.now() + oneWeekInMilliseconds),
            };
            return [...workPackages, newWorkPackage];
        });
    };

    const addNewMilestone = () => {
        setMilestones((milestones) => {
            const prevMilestone =
                milestones.length > 0
                    ? milestones[milestones.length - 1]
                    : null;
            const milestoneId = prevMilestone?.id ? prevMilestone?.id + 1 : 1;
            const newMilestone = {
                id: milestoneId,
                name: milestoneNames[(milestoneId - 1) % milestoneNames.length],
                date: new Date(prevMilestone?.date ?? Date.now()),
            };

            return [...milestones, newMilestone];
        });
    };

    const handleDeleteMilestone = (milestoneId: number) => {
        setMilestones((milestones) => {
            return [...milestones.filter((m) => m.id !== milestoneId)];
        });
    };

    const projectPlanLinkCreator = useCreateProjectPlanLink();

    return (
        <Stack gap={2}>
            <Stack
                gap={1}
                direction={'row'}
                sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Typography variant="h1">Project plan</Typography>
                <Button
                    onClick={() => {
                        (guid
                            ? UpdateProjectPlan(
                                  {
                                      name: projectName,
                                      workPackages,
                                      milestones,
                                  },
                                  guid,
                              )
                            : projectPlanLinkCreator.execute({
                                  name: projectName,
                                  workPackages,
                                  milestones,
                              })
                        ).then((r) => {
                            window.history.pushState(
                                {},
                                '',
                                `${window.location.origin}/${r.slice(1, -1)}`,
                            );
                            setToasterMessage(
                                guid
                                    ? 'Project plan saved.'
                                    : 'Project plan saved. Bookmark the page or copy the url to get back to this project plan.',
                            );
                            setToasterSeverity('success');
                            setShowCopyLinkButton(!guid);

                            setGuid(r.slice(1, -1));
                        });
                    }}
                    disabled={projectPlanLinkCreator.isPending}
                >
                    {guid ? 'Save' : 'Create link | save'}
                </Button>
                {guid && (
                    <Button
                        onClick={() => {
                            DeleteProjectPlan(guid);
                            setProjectName(initialProjectName);
                            setWorkPackages([initialWorkPackage]);
                            setMilestones([initialMileStone]);
                            setToasterSeverity('success');
                            setToasterMessage('Project plan deleted.');
                            setGuid(null);
                            window.history.pushState(
                                {},
                                '',
                                `${window.location.origin}`,
                            );
                        }}
                        variant="secondaryAction"
                    >
                        Delete link
                    </Button>
                )}
            </Stack>
            <TextField
                label="Project name"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
            />
            <Stack gap={1}>
                <Button sx={{ flexGrow: 0 }} onClick={addNewWorkpackage}>
                    Add work package
                </Button>
                {workPackages.map((workPackage) => (
                    <Stack
                        key={workPackage.id}
                        direction={'row'}
                        gap={1}
                        alignItems={'center'}
                    >
                        <TextField
                            label="name"
                            value={workPackage.name}
                            onChange={(e) => {
                                setWorkPackages((workPackages) =>
                                    workPackages.map((wp) =>
                                        wp.id === workPackage.id
                                            ? {
                                                  ...wp,
                                                  name: e.target.value,
                                              }
                                            : wp,
                                    ),
                                );
                            }}
                        />
                        <DatePicker
                            label="Start date"
                            value={workPackage.startDate}
                            onChange={(date) => {
                                if (date)
                                    setWorkPackages((workPackages) =>
                                        workPackages.map((wp) =>
                                            wp.id === workPackage.id
                                                ? {
                                                      ...wp,
                                                      startDate: date,
                                                  }
                                                : wp,
                                        ),
                                    );
                            }}
                            renderInput={function (
                                props: TextFieldProps,
                            ): ReactElement<
                                any,
                                string | JSXElementConstructor<any>
                            > {
                                return <TextField {...props} />;
                            }}
                        />
                        <DatePicker
                            label="End date"
                            value={workPackage.endDate}
                            onChange={(date) => {
                                if (date)
                                    setWorkPackages((workPackages) =>
                                        workPackages.map((wp) =>
                                            wp.id === workPackage.id
                                                ? {
                                                      ...wp,
                                                      endDate: date,
                                                  }
                                                : wp,
                                        ),
                                    );
                            }}
                            renderInput={function (
                                props: TextFieldProps,
                            ): ReactElement<
                                any,
                                string | JSXElementConstructor<any>
                            > {
                                return <TextField {...props} />;
                            }}
                        />
                        <TextField
                            label="resources"
                            value={workPackage.resources}
                            onChange={(e) => {
                                setWorkPackages((workPackages) =>
                                    workPackages.map((wp) =>
                                        wp.id === workPackage.id
                                            ? {
                                                  ...wp,
                                                  resources: e.target.value,
                                              }
                                            : wp,
                                    ),
                                );
                            }}
                        />
                    </Stack>
                ))}
            </Stack>
            <Stack gap={1}>
                <Button sx={{ flexGrow: 0 }} onClick={addNewMilestone}>
                    Add milestone
                </Button>
                {milestones.map((milestone) => (
                    <Stack
                        key={milestone.id}
                        direction={'row'}
                        gap={1}
                        alignItems={'center'}
                    >
                        <TextField
                            label="name"
                            value={milestone.name}
                            onChange={(e) => {
                                setMilestones((milestones) =>
                                    milestones.map((m) =>
                                        m.id === milestone.id
                                            ? {
                                                  ...m,
                                                  name: e.target.value,
                                              }
                                            : m,
                                    ),
                                );
                            }}
                        />
                        <DatePicker
                            label="date"
                            value={milestone.date}
                            onChange={(date) => {
                                if (date)
                                    setMilestones((milestones) =>
                                        milestones.map((m) =>
                                            m.id === milestone.id
                                                ? {
                                                      ...m,
                                                      date: date,
                                                  }
                                                : m,
                                        ),
                                    );
                            }}
                            renderInput={function (
                                props: TextFieldProps,
                            ): ReactElement<
                                any,
                                string | JSXElementConstructor<any>
                            > {
                                return <TextField {...props} />;
                            }}
                        />
                        <Button
                            variant="secondaryAction"
                            onClick={() => handleDeleteMilestone(milestone.id)}
                        >
                            <DeleteForeverIcon />
                        </Button>
                    </Stack>
                ))}
            </Stack>
            <Snackbar
                onClose={() => {
                    setToasterMessage(null);
                    setShowCopyLinkButton(false);
                }}
                open={toasterMessage !== null}
            >
                <Alert severity={toasterSeverity}>
                    {toasterMessage}
                    {showCopyLinkButton && (
                        <Button
                            variant="text"
                            onClick={() =>
                                navigator.clipboard.writeText(
                                    window.location.toString(),
                                )
                            }
                        >
                            Copy link
                        </Button>
                    )}
                </Alert>
            </Snackbar>
            <Button
                onClick={() =>
                    powerpointPlanGenerator.execute({
                        name: projectName,
                        workPackages,
                        milestones,
                    })
                }
                disabled={powerpointPlanGenerator.isPending}
            >
                Download
                {powerpointPlanGenerator.isPending && 'ing...'}
            </Button>
        </Stack>
    );
}

export default TimelineForm;
