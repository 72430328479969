import { ProjectPlan } from '@decidalo-frontend/features/powerpoint-project-plan/data-access/models';
import { downloadFile } from '@decidalo-frontend/helpers';

export function GeneratePowerpointPlan(projectPlan: ProjectPlan) {
    return fetch(
        `${process.env['NX_PUBLIC_PROJECTPLAN_BACKEND_URL']}/api/GeneratePowerpointPlan`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'blob',
            },
            body: JSON.stringify(projectPlan),
        },
    )
        .then(
            (response) => response.blob(),
            (e) => {
                console.log(e);
                throw e;
            },
        )
        .then((data) => {
            if (data) downloadFile(data, 'project-plan.pptx');
        })
        .catch((e) => console.error(e));
}
