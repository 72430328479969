import { AxiosInstance, HeadersDefaults, default as axioslib } from 'axios';
import i18n from 'i18next';
import { RegexConstants } from '../constants/regex-constants';
import { convertUtcToLocalDate } from '../localization/localizedParser/localizedParser';

const axiosMainInstance = axioslib.create({
    baseURL: process.env['NX_PUBLIC_MAIN_HOST_URL'],
    headers: getDefaultHeaders(),
});

const axiosMarketplaceInstance = axioslib.create({
    baseURL: process.env['NX_PUBLIC_MARKETPLACE_URL'],
    headers: getDefaultHeaders(),
});

setRequestInterceptors(axiosMainInstance);
setRequestInterceptors(axiosMarketplaceInstance);

setResponseInterceptors(axiosMainInstance);
setResponseInterceptors(axiosMarketplaceInstance);

function getDefaultHeaders(): Partial<HeadersDefaults> {
    return {
        common: {
            'Content-Type': 'application/json; charset=utf-8',
            Accept: 'application/json',
        },
        put: {
            'Content-Type': 'application/json; charset=utf-8',
        },
    };
}

function setRequestInterceptors(axiosInstance: AxiosInstance): void {
    axiosInstance.interceptors.request.use((config) => {
        if (config && config.headers) {
            config.headers['Accept-Language'] = i18n.language;
        }
        return config;
    });
}

function setResponseInterceptors(axiosInstance: AxiosInstance): void {
    axiosInstance.interceptors.response.use((originalResponse) => {
        convertDatesToLocalTimezone(originalResponse.data);
        return originalResponse;
    });
}

// Validates if value is date string
function isIsoDateString(value: any): boolean {
    return (
        value &&
        typeof value === 'string' &&
        RegexConstants.IsoDateFormat.test(value)
    );
}

// Function that will adjust and convert all dates in response to local timezone
// Recursively going through data until all dates are adjusted to local timezone
function convertDatesToLocalTimezone(body: any) {
    if (body === null || body === undefined || typeof body !== 'object')
        return body;

    for (const key of Object.keys(body)) {
        const value = body[key];

        // We still need to go into the depth as value is not plain type in this moment
        if (typeof value === 'object') convertDatesToLocalTimezone(value);

        // Convert date string in utc timezone to local timezone date type
        if (isIsoDateString(value)) body[key] = convertUtcToLocalDate(value);
    }
}

type HostType = 'marketplace' | 'main';

export const axios = axiosMainInstance;

export const axiosFactory = {
    resolveAxios(hostType: HostType) {
        switch (hostType) {
            case 'marketplace':
                return axiosMarketplaceInstance;
            case 'main':
                return axiosMainInstance;
            default:
                return axiosMainInstance;
        }
    },
};
